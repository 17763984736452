$article-edit__A0: themed('info--color') !default;

.me__article-edit{
  &__inner{
    form{
      fieldset{
        border: none;
      }
    }
    display: block;
    width: 100%;
    height: calc( 100% - 31px );
  }

  &__inner-content{
    width: 100%;
    max-width:625px;
    float:right;
  }


  &__none-selected{
    display: block;
    width: 100%;
    height: calc( 100% - 31px );
    padding: 10px;
  }
  &__legend{
    &:first-of-type{
      margin-top: 5px;
    }
    display: block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    padding-left: 10px;
  }
  &__properties{

  }
  &__properties-info{
    span{
      display: inline-flex;
      width: auto;
      height: 100%;
      padding-top: 10px;
      color: $article-edit__A0;
    }
    p{
      display: inline-flex;
      height: 100%;
      flex-grow: 1;
      margin-left: 10px;
    }
    display: flex;
  }
  display: block;
  width: 100%;
  height: 100%;
}
