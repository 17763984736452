$sortable-list__A0: themed('list:selected--bg-color') !default;
$sortable-list__borders: themed('frame--bg-color') !default;
$sortable-list__B0: themed('list--bg-color') !default;
$sortable-list__C0: themed('list:odd--bg-color') !default;
$sortable-list__D0: themed('base--text-color') !default;
$sortable-list__D1: Roboto Light; //@todo app font
$sortable-list__E0: $iconFontFamily; //themed('') !default;
$sortable-list__E1: themed('base--text-color') !default;
$sortable-list__G0: themed('list:even--bg-color') !default;

sortable-list{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.me__sortable-list{

  &__draggable{
    &:hover{
      background-color: $sortable-list__A0;
    }
    &:focus {
      outline: none;
    }

    &--error{
      cursor:default;
      $trans: .5;
      filter: alpha(opactiy=($trans * 100));
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opactiy=#{$trans * 100})";
      -moz-opacity: $trans;
      -khtml-opacity: $trans;
      opacity: $trans;
    }

    &--last{
      border: 1px solid $sortable-list__borders;
    }

    &--selected{
      background-color: $sortable-list__A0;
    }

    &:nth-child( odd ):not(.me__sortable-list__draggable--selected){
      &:hover{
        background-color: $sortable-list__A0;
      }
      background: $sortable-list__B0;
    }
    &:nth-child( even ):not( .me__sortable-list__draggable--selected ){
      &:hover{
        background-color: $sortable-list__A0;
      }
      background: $sortable-list__G0;
    }
    display:flex;
    width: auto;
    height:30px;
    flex-wrap: wrap;
    cursor:pointer;
    border-top: 1px solid $sortable-list__borders;
    border-left: 1px solid $sortable-list__borders;
    border-right: 1px solid $sortable-list__borders;
    border-bottom: none;
    background-color:$sortable-list__B0;
  }

  &__label{
    &.no-preview{
      max-width: 190px;
    }
    span{
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 10px;
    }
    display: inline-flex;
    flex-grow: 1;
    height:30px;
    color:$sortable-list__D0;
    line-height: 30px;
    text-align: left;
    max-width: calc( 100% - 75px);
    font-family: $sortable-list__D1;
    font-size: 12px;
  }

  &__button{
    width:30px;
    height:30px;
    font-family: $sortable-list__E0;
    color:$sortable-list__E1;
    font-size: 14px;
    line-height: 30px;
    text-align: center;

    &--left{
      border-right: 1px solid $sortable-list__borders;
    }
    &--left:hover{
      background-color: $sortable-list__A0;
    }
  }

  &__preview{
    display:block;
    position:absolute;
    width:300px;
    height:300px;
    top:0px;
    left:100%;
    transform-origin: top left;
    transform: scale(0.3);
  }
  display:block;
  position:relative;
  float:left;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
