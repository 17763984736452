$custom-area__A1: #fefefe;

.custom-area-container, .custom-area-container-inner{
  display: block;
  width: 100%;
  height: 100%;
}

.custom-area-container-no-content{
  color: $custom-area__A1;
}
