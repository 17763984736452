$attachment-edit__A0: themed('frame--bg-color') !default;
$attachment-edit__B0: red; //@todo ?
$attachment-edit__C0: themed('frame--bg-color') !default;

.me__attachment-edit{
  &__legend{
    &:first-of-type{
      margin-top: 5px;
    }
    display: block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    padding-left: 10px;
  }
  .me__attachment-edit__container-left{
    height:100%;
    display:flex;
    flex-direction:column;
    overflow:hidden;
  }

  .me__attachment-edit__folder-list{
    border-bottom: 6px solid $attachment-edit__A0;
    display:block;
    height:0;
    flex-grow:1;
  }

  .me__attachment-edit__drop-zone{
    max-height:60px;
    min-height:30px;
  }

  .me__attachment-list{
    &__container{
      position: relative;
      z-index: 100;
      height: 100%;
    }
    &__shade{
      position: absolute;
      display: block;
      padding: 20px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      pointer-events: none;
    }
    &__shade-inner{
      display: block;
      width: 100%;
      height: 100%;
      background: #000;
    }
    .me__attachment-list-item{
      .me__attachment-list-item-inner{
        display: block;
        width: 100%;
        height: 100%;
      }
      display: block;
      width: calc( 100% - 50px );
      height: auto;
      min-height: 125px;
      background: $attachment-edit__B0;
      margin-bottom: 20px;
      padding: 25px;
    }
    display: block;
    width: 100%;
    height: auto;
    min-height: 500px;
    padding: 20px 40px;
  }
}
