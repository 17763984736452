//@todo: to theme
$attachment-item__A0: themed('base--bg-color') !default;
$attachment-item__B0: themed('base--text-color') !default;
$attachment-item__C0: themed('base--text-color') !default;
$attachment-item__D0: themed('base:accent--text-color') !default;
$attachment-item__E0: themed('element-action:active--bg-color') !default;

.me__attachment{
  display: block;
  width: 100%;
  height: auto;
  background: #000;
  padding: 15px;
}

.me__attachment-item{
  &___properties{
    display: block;
    width: 100%;
    height: 60px;
  }
  &__icon{
    display: inline-block;
    width: 45px;
    vertical-align: top;
    padding-left: 10px;
    padding-top: 8px;
    padding-right: 5px;
  }
  &__display{
    display: inline-block;
    width: calc( 100% - 50px );
    vertical-align: top;
    padding-left: 10px;
    padding-top: 5px;
    font-size: 1rem;
  }
  &__title{
    &.attachment-template{
      background: #ccc;
      height: 14px;
      border-radius: 4px;
      margin-bottom: 5px;
    }
    display: block;
    width: calc( 100% - 50px );
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }
  &__description{
    &.attachment-template{
      background: #ccc;
      height: 14px;
      border-radius: 4px;
    }
    display: inline-block;
    width: auto;
    vertical-align: top;
    padding: 5px 15px 5px 0;
    opacity: .75;
  }
  &__menu{
    &:hover,
    &.active {
      .me__article-menu__box-header {
        display: block;
      }
      outline-color: rgba($attachment-item__E0, 0.3);
    }
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    z-index: 112;
    margin: 1px;
    font-size: 12px;
    top: 0;
    left: 0;
    outline: 1px solid transparent;
  }
  &__tool-bar{
    position: absolute;
    background: $attachment-item__E0;
    width: 30px;
    font-size: 12px;

    &--main{
      right: -30px;
    }

    &--top{
      left: 50%;
      top: -30px;
    }

    &--bottom{
      left: 50%;
      bottom: -30px;
    }
  }
  display: block;
  position: relative;
  background: $attachment-item__A0;
  padding: 8px 0;
  height: 60px;
  width: 100%;
}


