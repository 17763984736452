$widget__A0: themed('base:accent--color') !default;

.me__widget{
  .me__widget-box{
    &:hover,
    &.active{
      background-color: rgba( $widget__A0, 0.3);
    }
    position: absolute;
    display: block;
    width: calc( 100% - 2px );
    height: calc( 100% - 2px );
    background: transparent;
    outline: none;
    z-index: 112;
    margin: 1px;
    font-size: 12px;
  }
  position: relative;
  width: auto;
  height: auto;
}
