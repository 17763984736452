//OptinHistorySix
.optinhistory_six {
  .optinhistory_table {
    thead{
      tr{
        th{
          cursor: default;
        }
      }
    }
    tbody {
      tr {
        &.dtRow{
          cursor: default;
        }
        &.even {
          background: #1f2523;
        }
        &.odd {
          background: #282e2c;
        }
      }
      td {
        &.log_datum_six {
          white-space: nowrap;
        }
      }
    }
    border-bottom: 1px solid #000;
  }
  .noContent {
    padding: 10px 0px 0px 10px;
    color: #fefefe;
  }
}


