$tooltip__A0: themed('base--text-color') !default;
$tooltip__A1: themed('base--bg-color') !default;

$tooltip__A2: themed('tablelist--bg-color') !default;
$tooltip__A3: themed('tablelist:odd--bg-color') !default;
$tooltip__borders: themed('frame--bg-color') !default;


.me__tooltip{

  width:450px;
  min-height:30px;
  color:$tooltip__A0;
  background-color: $tooltip__A1;
  border: 1px solid $tooltip__borders;
  max-height:400px;

  table {
    width:100%;
    border-collapse: collapse;
  }

  tr.me__tooltip__title{
    font-size:14px;
  }

  td + td, th + th{
    border-left: 1px solid $tooltip__borders;
  }

  tr:not(:last-child){
    height:30px;
    border-bottom: 1px solid $tooltip__borders;
  }

  td, th{
    padding: 5px;
  }

  .odd{
    background-color: $tooltip__A2; //#404644
  }

  .even{
    background-color: $tooltip__A3;
  }
}
