$sidebar-edit__A0: themed('base--text-color') !default;

.me__sidebar-edit{
  &__header{
    display: flex;
    width: 100%;
    height: auto;
    line-height: 30px;
    border-bottom: 1px solid #000;
  }
  &__header-item{
    display: inline-flex;
    width: auto;
    height: 100%;
    padding: 0 10px;
  }
  &__header-inner{
    display: inline-flex;
    flex-grow: 1;
    height: 100%;
  }
  &__item-show{
    display: flex;
    width: 100%;
    height: 30px;
    line-height: 30px;
  }
  &__item-title{
    display: inline-flex;
    flex-grow: 1;
    height: 100%;
    color: $sidebar-edit__A0;

  }
  &__item-select{
    .chosen-container:after{
      top: 0;
    }
    display: inline-flex;
    width: 160px;
    height: 100%;
    margin-left: 4px;
  }
  &__body{
    display: block;
    width: 100%;
    height: calc( 100% - 30px );
    position: relative;
  }
  &__article-title{
    flex-grow: 1;
  }

  height: 100%;
}
