//>Mailing Analyse
.mailsequence_timebar_six {
  .around {
    .timebar {
      thead {
        background-color: #474949;
        tr {
          th {
            &:last-child {
              border-right: none;
            }
            padding: 8px;
            border-right: 1px solid #000;
            border-bottom: 1px solid #000;
          }
        }
      }
      tbody {
        tr {
          &.even {
            background: #474949;
          }
          &.odd {
            background: #6f6f6f;
          }
        }
        td {
          &:last-child {
            border-right: none;
          }
          padding: 8px;
          border-right: 1px solid #000;
        }
      }
      color: #fefefe;
      width: 100%;
    }
    font: normal 12px Tahoma, Verdana, Arial;
  }
  height: 100%;
}


