.me__complex-list {

  .me__complex-list__add {
    flex-direction: row-reverse;
  }

  .me__complex-list__container {
    display: block;
    width: 100%;
    height: 150px;
  }

  .me__complex-list__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }

  .me__complex-list__item {
    display: block;
    width: 100%;
    height: 30px;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 5px;
  }

  .me__complex-list__dots {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }

  .me__complex-list__input {
    flex-grow: 1;
  }

}
