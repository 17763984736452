.me__complex-switch-image {

  @include devicesTabs();

  &__thumbnail{
    img{
      height: 100%;
    }
    width:100%;
    height:120px;
    overflow:hidden;
    position: relative;
  }
  &__thumbnail-inner{
    display: inline-block;
    position: relative;
    width: auto;
    height: 100%;
  }

  &__imageurl-text{
    max-width: 317px;
    flex-grow:1;
  }
  &__link-text{
    flex-grow:1;
    max-width: 447px;
  }

  &__input {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
