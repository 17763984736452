.me__complex-link {

  .me__complex-link__linkurl-input {
    flex-grow: 1;
    max-width: 469px;
  }

  &__input {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
