
// ________________________________________________________________ ELEMENTS



// Inputs
$md-input-container__A0: $fontFamilyLight;

md-input-container{
  &.md-input-focused, &.md-input-has-placeholder, &.md-input-has-value{
    label{
      &:not(.md-no-float){
        transform: translate3d(0, 3px, 0) scale(1);
      }
    }
  }
  &:not( .md-input-has-value ){
    label{
      opacity: .5;
    }
  }
  *{
    &[ disabled ] {
      opacity: .5;
    }
    font-family: $md-input-container__A0;
  }

  label:not(.md-container-ignore) {
    @include labelText;
  }

  .md-input, input {
    &:focus{
      background-color: #797d7c;
    }
    @include inputText;
  }

  .md-errors-spacer {
    float: right;
    min-height: 24px;
    min-width: 0px;
    display:none;
  }

  &.me__grow_1{
    flex-grow:1;
  }

  margin: 0;
  padding:0;
  min-width:0;
}


.me__label{
  @include labelText;
  &--fix{
    @include labelText;
    transform:translate3d(0, 3px, 0) scale(1) !important;
    opacity: 1 !important;
  }
  &--top{
    z-index: 3 !important;
  }
  &--left{
    @include labelText;
    transform:translateX(-37px) scale(1) !important;
    opacity: .5;
  }
}


// Buttons

$md-button__A0: themed('element-action--text-color') !default;
$md-button__A1: themed('element-action--bg-color') !default;
$md-button__B0: "elaine-six"; //@todo: app font
$md-button__D0: $eColorLight;
$md-button__E0: themed('element-action:hover--bg-color') !default;
$md-button__E1: themed('element-action:active-highlight--bg-color') !default;
$md-button__F0: themed('element-action:hover--bg-color') !default;
$md-button__G0: themed('base--bg-color') !default;
$md-button__H0: themed('element-action:hover-cancel--bg-color') !default;
$md-button__I0: themed('form-element--bg-color') !default;
$md-button__J0: themed('form-element:focus--bg-color') !default;

.md-button{
  &[disabled] {
    opacity: .5;
  }
  &.me__button {

    font-size: 12px;
    border: none;
    border-radius: 0;
    color: $md-button__A0;
    background: $md-button__I0;
    height: 30px;
    min-height: 30px;
    line-height: 30px;
    outline: none;
    vertical-align: top;
    text-align: center;
    display: inline-block;
    margin: 0;
    padding: 0 10px;
    text-transform: none;

    &:focus {
      outline: none;
    }

    &:not( .me__button--icon ){
      min-width: 87px;
      width: auto;
    }

    &.me__button--icon{
      min-width: 30px;
      height: 30px;
      width:30px;
      padding: 0;
      font-family: $md-button__B0;
    }

    &.me__button--icon-big{
      min-width: 100px;
      height: 30px;
      width:120px;
      padding: 0;
      font-family: $md-button__B0;
    }

    &.active{
      background: $md-button__E0;
    }

    &[disabled]{
      opacity: .4;
    }

    &:hover{
      &:not( [disabled] ) {
        background: $md-button__E1;
      }
    }

    &.me__button--colored{
      background: $md-button__F0;
    }

    &.me__button--dark{
      background: $md-button__G0;
    }

    &--cancel:hover{
      &:not( [disabled] ) {
        background: $md-button__H0;
      }
    }

  }

}

$md-switch__A0: themed('element-action:active--bg-color') !default;
$md-switch__A1: themed('element-action:active--bg-color') !default;
$md-switch__B0: themed('element-action:constrast--bg-color') !default;
$md-switch__C0: themed('base--text-color') !default;

md-switch{
  &.md-checked{
    .md-bar {
      background-color: $md-switch__A0;
      border-color: $md-switch__A1;
    }
  }
  .md-thumb{
    width: 18px;
    height: 18px;
    top: 4px;
    left: 4px;
    background-color: $md-switch__B0;
  }
  .md-bar {
    top: 4px;
    background-color: transparent;
    border: 1px solid $md-switch__C0;
    height: 22px;
    width: 38px;
    border-radius: 15px;
  }

  .md-container{
    height: 100%;
  }
  color: $md-switch__C0;
  margin: 0;
}


// @todo create file for md-menu-overide

$md-item__A0: themed('frame--bg-color') !default;
// for md-dialog
.md-scroll-mask{
  z-index: 3100;
}
.md-dialog-container{
  z-index: 3101;
}

md-backdrop.md-dialog-backdrop{
  z-index: 3101;
  background: $md-item__A0;
}

md-backdrop.md-menu-backdrop{
  z-index: 3101;
}


$md-item__B0: themed('base:accent--text-color') !default;
$md-item__B1: themed('base--text-color') !default;
$md-item__B2: themed('base:accent--text-color') !default;
$md-item__B3: themed('base--bg-color') !default;

// for md menu
.md-open-menu-container{
  .no-padding-item{
    padding: 0 !important;
    .md-button{
      min-height: 30px !important;
    }
  }

  &.md-active{
    md-menu-content{
      padding-top: 0px;
      max-height: 500px;
      overflow: hidden;
      &.limited-menu{
        max-height: 350px;
      }
      &.menu-global-filters{
        max-height: 250px;
        scroll-margin-right: initial;
        overflow: auto;
      }
      &.limited-menu-editlink{
        max-height: 200px;
        scroll-margin-right: initial;
        overflow: auto;
      }
    }
    md-menu-item {
      &.divider_small{
        min-height: 3px;
        max-height: 3px;
      }
      md-menu-divider{
        background: #404644;
        margin-left: 10px;
        margin-right: 10px;
      }
      .md-button, .me__menu-item{
        &:hover{
          &:not( [disabled] ) {
            color: $md-item__B0;
          }
        }
        height: 30px;
        font-size: 12px;
        line-height: 30px;
      }

      .me__menu-item{
        cursor:pointer;
        position:relative;
        width:100%;
      }

      .me__menu-item__icon{
        position:absolute;
        right: -10px;
        top: 0;
      }

      min-height: 30px;
      height: 31px;
      color: $md-item__B1;

      &.advice{
        color: $md-item__B2;
      }
    }
    //background-color: red; //$md-item__B3;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.6);
    box-shadow: 0 6px 12px rgba(0,0,0,0.6);
    -moz-border-radius: 0;
    border-radius: 0;
    z-index: 3102;
  }
}
.navigation-more-button::before {
  content: url(/gui/fonts/six/icons/more.svg);
  width: 20px;
  height: 20px;
  box-sizing: border-box;
}
//@todo: menu devices kapoot?
.md-open-menu-container.md-active{
  background-color: $md-item__B3;
}
