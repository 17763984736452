$breadcrumb__A0: themed('frame--bg-color'); //theme
$breadcrumb__A1: themed('base--text-color');
$breadcrumb__A2: themed('base--bg-color');
$breadcrumb__A3: themed('element-action:hover--bg-color');
$breadcrumb__A4: themed('neutral--color');
$breadcrumb__c0: themed('layer-0--color');
$breadcrumb__c1: themed('layer-1--color');
$breadcrumb__c2: themed('layer-2--color');
$breadcrumb__c3: themed('layer-3--color');
$breadcrumb__c4: themed('layer-4--color');
$breadcrumb__c5: themed('layer-5--color');

$breadcrumb__c6: themed('element-action:active--bg-color') !default;
$breadcrumb-height: 30px;
$border: 2px;

@mixin item-hover( $color ) {
  color: $color;
  &:hover{
    border-bottom: $border solid $color;
  }
}

.me__editor-breadcrumb{
  display: block;
  position: absolute;
  width: 100%;
  height: $breadcrumb-height ;
  border-top: 1px solid $breadcrumb__A0;
  bottom: 0;
  color: $breadcrumb__A1;
  background-color: $breadcrumb__A2;
  z-index: 3000;

  .me__editor-breadcrumb__item{
    margin: 0;
    content: '';
    position: relative;
    float: left;
    line-height: $breadcrumb-height;
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: $border solid $breadcrumb__A2;
    margin-left: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:first-child {
      margin-left: 16px;
    }

    &:first-child:before {
      content: '';
    }
    &.color-level-0{
      @include item-hover( $breadcrumb__c0 );
    }

    &.color-level-1{
      @include item-hover( $breadcrumb__c1 );
    }

    &.color-level-2{
      @include item-hover( $breadcrumb__c2 );
    }

    &.color-level-3{
      @include item-hover( $breadcrumb__c3 );
    }

    &.color-level-4{
      @include item-hover( $breadcrumb__c4 );
    }

    &.color-level-5{
      @include item-hover( $breadcrumb__c5 );
    }

    &:last-child{
      @include item-hover( $breadcrumb__c6 );
    }

    &:before{
      color: $breadcrumb__A4;
      vertical-align: sub;
      left: -20px;
      top: 15px;
      transform: translate(0, -50%);
      position: absolute;
    }
  }
}
