//TREE
.tree{
  .treecontainer{
    .tree_body{

    }
    ul{
      &.tree_root{
        > li{
          &:last-of-type{
            &.opened{
              border-bottom: none;
              border-top: 1px solid #000;
            }
            border-bottom: 1px solid #000;
          }
          &.opened{
            border-bottom: none;
          }
          border-top: 1px solid #000;
          margin-bottom: 0;
        }
      }
      li{
        &:hover,&.tree_selected,&:focus,&:active,&.tree_active{
          ul{
            background: none;
          }
          background: #6d891b;
        }
        a{
          span{
            min-width: 10px;
            display: inline-block;
          }
          cursor: pointer;
          color: #fefefe;
          padding-left: 3%;
          min-height: 30px;
          display: inline-block;
          width: 10%;
        }
        .tree_item_label{
          &.uppercase{
            text-transform: uppercase;
          }
          font-weight: 100;
          cursor: pointer;
          line-height: 30px;
          min-height: 30px;
          display: inline-block;
          width: 89%;
        }
        background: transparent;
        color: #fefefe;
        border-radius: 0;
        line-height: 30px;
        padding: 0;
        border: none;

      }
      background: transparent;
      border: none;
      border-radius: 0;
    }
    .tree_subtree {
      padding: 0;
      list-style: none;
      margin: 0;
    }
    background: transparent;
    width: 100%;
  }
  font-family: $fontFamilyLight;
  margin: 0px;
  padding: 0px;
  overflow-y: hidden;
  overflow-x: hidden;
  background: transparent !important;
}
