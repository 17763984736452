//Font Faces

@import url(/gui/fonts/six/elaine-six.css);

@font-face {
  font-family: Roboto Thin;
  font-weight: normal;
  font-style: normal;
  src: url('/gui/fonts/RobotoTTF/Roboto-Thin.woff2') format('woff2'),
  url('/gui/fonts/RobotoTTF/Roboto-Thin.ttf') format('truetype')
}

@font-face {
  font-family: Roboto Light;
  font-weight: normal;
  font-style: normal;
  src: url('/gui/fonts/RobotoTTF/Roboto-Light.woff2') format('woff2'),
  url('/gui/fonts/RobotoTTF/Roboto-Light.ttf') format('truetype')
}
//Fonts
$iconFontFamily: 'elaine-six';
$fontFamilyThin: Roboto Thin;
$fontFamilyLight: Roboto Light;

//Texts
$textColorDefault: #000;

//Boxes and shadows
$boxShadow: 0 6px 12px rgba( 0,0,0,0.6 );

$artegicGreen : #9cc318;  //#a8c33d; //#6d891b //#95aa5d


$eColorContrast : $artegicGreen;
$eColorContrastDark: darken( $eColorContrast, 30% ); //#6d891b; // #6d8a1c
$eColorDark : #000000; // #000
$eColorLight : #404644; //#4a4d4a
$eColorBase : #282e2c;
$eColorWhite : #fefefe;
$eColorBaseDark : #212421;
$eColorLightDark : #424542;


$buttonCancelBG : #c31a1d;

//Toaster
$toaster-success-color: #9ec21a;
$toaster-error-color: #c31a1d;
$toaster-warning-color: #c28325;
$toaster-info-color: #fefefe;

//Scroll
$scrollInnerColor: #585e5e;
