//Mailing Analyse
.analyse_six {
  .analyse_content {
    width: 100%;
    b {
      color: #ff0000;
    }
  }
}

