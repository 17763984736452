$product-logo__A0: themed('base:accent--color') !default;
$product-logo__B0: themed('base--text-color') !default;

.e__product-logo{
  &.e__product-logo--small{
    .e__product-logo__name{
      width: 40px;
      font-size: 10px;
      line-height: 10px;
    }
    width: 50px;
    height: 50px;
    margin: 8px;
  }
  .e__product-logo__name{
    color: $product-logo__B0;
    width: 50px;
    font-size: 12px;
    line-height: 12px;
  }
  background: $product-logo__A0;
  height: 65px;
  width: 65px;
  padding: 5px 7px;
  margin: 10px;
}
