.me__preview-attachments {
    height: 100%;

    .em-attachment-item{
        &.dragging {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }

        &.active {
            border: 1px solid #6d8a1c;
        }

        .em-attachment-item-inner {
            .em-attachment-item-inner-upper {
                h4 {
                    margin: 0;
                    font-size: 14px;
                }

                display: block;
                width: 100%;
                padding: 2px 0 10px 0;
            }

            .em-attachment-item-inner-lower {
                .em-attachment-filename {
                    padding-bottom: 10px;
                    font-size: 14px;
                }
                .em-attachment-item-inner-left {
                    .preview-show {
                        .e_icn {
                            color: #9ec21a;
                        }

                        color: #9ec21a;
                        cursor: pointer;
                    }

                    display: inline-block;
                    width: 49%;
                    height: 100%;
                }

                .em-attachment-item-inner-right {
                    display: inline-block;
                    width: 49%;
                    height: 100%;
                    vertical-align: top;
                }

                span {
                    &.spec {
                        display: inline-block;
                        width: 50%;
                        height: 50%;
                        vertical-align: top;
                    }
                    &.spec1 {
                        display: inline-block;
                        padding-right: 2px;
                        vertical-align: top;
                    }
                }

                display: block;
                width: 100%;
                padding: 2px 0  0 38px;
            }

            .em-attachment-download {
                position: absolute;
                top: 45px;
                left: 22px;
                transform: scale(2.5);
                cursor: pointer;
            }

            display: block;
            //width: 100%;
            //height: 100%;
            padding-top: 2px;
        }

        .em-attachment-item-preview {
            &.ng-enter, &.ng-leave {
                -moz-transition: .5s linear all;
                -webkit-transition: .5s linear all;
                transition: .5s linear all;
            }

            &.ng-enter {
                &.ng-enter-active {
                    max-height: 200px;
                    opacity: 1;
                }

                max-height: 0;
                opacity: 0;
            }

            &.ng-leave {
                &.ng-leave-active {
                    max-height: 0;
                    opacity: 1;
                }

                max-height: 200px;
                opacity: 1;
            }

            .em-attachment-item-preview-inner {
                .em-attachment-item-preview-inner-container {
                    .em-attachment-item-preview-noshow {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        min-height: 198px;
                        background: #fefefe;
                        color: #000;
                        padding: 10px;
                    }

                    embed {
                        width: 100%;
                        height: auto;
                        min-height: 196px;
                    }

                    img {
                        width: 100%;
                    }

                    display: inline-block;
                    width: 100%;
                    height: auto;
                    min-height: 195px;
                }

                display: block;
                width: 100%;
                height: 100%;
                background: #000;
            }

            display: block;
            width: 100%;
            height: 200px;
            border: 1px solid #000;
        }

        .em-attachment-menu {
            &.top {
                top: -30px;
                left: 50%;
                z-index: 113;
            }

            &.bottom {
                top: inherit;
                bottom: -30px;
                left: 50%;
                z-index: 113;
            }

            width: 30px;
            height: auto;
            background: #95aa5d;
            position: absolute;
            display: none;
            right: -30px;
            top: 0;
            z-index: 115;
        }

        .em-attachment-item-condition {
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            background: rgba(149, 170, 93, 0.75);
            text-align: center;
            padding: 2px 0;
        }

        position: relative;
        display: block;
        margin: 7px;
        background: #404644;
        color: #fefefe;
        padding: 2px 5px 5px 15px;
        text-align: left;
        cursor: default;
    }
}
