$folder-list__A0: themed('frame--bg-color') !default;
$folder-list__B0: themed('base--bg-color') !default;
$folder-list__C0: themed('list--bg-color') !default;
$folder-list__D0: themed('element-action:hover--bg-color') !default;

.me__folder-list{
  .me__sortable-placeholder{
    display: none !important;
  }
  .chosen-scroll{
    height: 100% !important;
    max-height: 700px;
  }
  .chosen-results{
    width: 100% !important;
  }
  *{
    user-select: none;
  }
  &__list{
    flex-grow: 1;
    position:relative;
  }
  &__drop{
    &:hover{
      .me__folder-list__drop-label {
        background-image: repeating-linear-gradient(135deg, $folder-list__D0, transparent 3px, transparent 3px, rgba(168, 195, 61, 0.75) 6px);
      }
    }
    display: flex;
    width: 100%;
    height: 30px;
    flex-wrap: wrap;
    cursor: default;
    border-right: 1px solid #000;
    border-bottom: none;
    background-color: #1f2523;
  }
  &__drop-label{
    flex-grow: 1;
    height: 30px;
    color: #fefefe;
    line-height: 30px;
    text-align: left;
    padding: 0 10px;
    font-size: 12px;
    background-image: repeating-linear-gradient(135deg, $folder-list__B0, transparent 3px, transparent 3px, $folder-list__B0 6px);
  }
  &__button-tab{
    display:flex;
    width: 100% ;
    height:30px;
    border-top: 1px solid $folder-list__A0;
    justify-content: flex-end;
    box-sizing: content-box;
    >*{
      border-left:1px solid $folder-list__A0;
    }
  }
  position: relative;
  display:flex;
  height: 100%;
  flex-direction:column;
  background-color:$folder-list__B0;
}
