.me__html-wysiwygshow{
  .mce-tinymce{
    min-height: 100% !important;
  }
  &__inner{
    display: table;
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
  display: block;
  width: 100%;
  height: calc( 100% - 30px );
}
