$view-container__A0: themed('base--bg-color') !default; //$eColorBase

preview-show{
  display: block;
  width: 100%;
  height: 100%;
}
view-container{
  &.full-stage{
    .me__stage-middle{
      width: 100%;
    }
  }
  .me__stage-middle{
    .me__view {
      > .stretch{
        overflow: visible;
      }
      &.ng-enter,&.ng-leave{
        -webkit-transition: opacity ease-in-out .3s;
        -moz-transition: opacity ease-in-out .3s;
        -ms-transition: opacity ease-in-out .3s;
        -o-transition: opacity ease-in-out .3s;
        transition: opacity ease-in-out .3s;
      }
      &.ng-enter {
        opacity: 0;
        position: initial;
      }
      &.ng-enter-active {
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      &.ng-leave {
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      &.ng-leave-active {
        opacity: 0;
        position: initial;
      }
      display: block;
      width: 100%;
      height: 100%;
      background: $view-container__A0; //@todo: ready
    }
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: top;
    float: left;
  }
  display: block;
  width: 100%;
  height: 100%;
}
