//Campaign Monitor
.campaignmonitor_six {
  .calendarDiv {
    width: auto;
  }
  .DatePicker {
    tr{
      height: 20px;
      line-height: 20px;
    }
    th {
      text-align: center;
      &.year {
        border-top: none;
        background-color: #282e2c;
        color: #ffffff;
      }
      &.month {
        background-color: #282e2c;
        color: #ffffff;
        border-top: 1px solid #000;
      }
      &.day {
        background-color: #282e2c;
        color: #ffffff;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
      }
      width: 20px;
      height: 20px;
    }
    td {
      background-color: #282e2c;
      border-color: #000;
      width: 20px;
      height: 20px;
    }
    div {
      &.over {
        background-color: #6d891b;
        border: none;
      }
      &.down {
        background-color: #6d891b;
        border: none;
      }
      &.selected {
        background-color: #6d891b;
        border: none;
      }
      background-color: #282e2c;
      border: none;
    }
    a {
      color: #fefefe;
    }
    border: 1px solid #000;
    width: auto;
  }
  .cal1 {
    a{
      text-decoration: none;
    }
    .empty_day {
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(../../system/interface/img/campaignmonitor/empty_day.gif);
    }
    tr {
      &.even {
        th, td {
          background-color: #282e2c;
        }
      }
      &.odd {
        th, td {
          background-color: #1f2523;
        }
      }
      &.selected {
        td {
          background-color: #6d891b;
        }
      }
      th, td {
        border: 1px solid #000;
      }
      td {
        &.groupDay {
          :hover {
            background-color: #6d891b;
          }
        }
        &.with_mailing_selected {
          &:hover {
            background-color: #6d891b;
          }
          background-color: #6d891b;
        }
      }
      &.weeks {
        th {
          &.control_left {
            a {
              display: block;
              padding-right: 10px;
              float: right;
            }
            text-align: right;
          }
          span {
            display: block;
            padding: 2px;
          }
          border: 1px solid #000;
          border-left: none;
          text-align: center;
        }
        height: 30px;
      }
      &.days {
        th {
          &.control_right {
            text-align: left;
          }
          &.control_left {
            text-align: right;
          }
          &.selected {
            span {
              &.wrapper {
                border: 1px solid #6d891b;
                padding: 0;
              }
            }
          }
          span {
            &.wrapper {
              padding: 2px;
              display: block;
            }
          }
          border: 1px solid #000;
          border-left: none;
          text-align: center;
          font-weight: normal;
        }
      }
      &.mailings {
        th {
          &.control_left {
            a {
              display: block;
              padding-top: 10px;
            }
          }
          &.with_mailing {
            span {
              padding-top: 5px;
            }
          }
          &.with_mailing_selected, &.selected {
            span {
              padding-top: 5px;
              border: 1px solid #6d891b;
            }
          }
          span {
            display: block;
            width: 25px;
            height: 25px;
            background-position: center center;
            background-repeat: no-repeat;
            margin-left: auto;
            margin-right: auto;
          }
          text-align: center;
        }
        height: 30px;
      }
      &.group {
        &.selected {
          th {
            span {
              &:last-child {
                border-left: none;
              }
              border: 1px solid #6d891b;
            }
          }
        }

        th {
          span {
            &:last-child {
              border-left: none;
            }
            display: block;
            padding-left: 10px;
            line-height: 30px;
            height: 30px;
            width: 100%;
            border: 1px solid transparent;
          }
          border: 1px solid #000;
          border-left: none;
          font-size: 12px;
          font-weight: normal;
          text-align: left;
          width: auto;
          overflow-y: hidden;
        }
        td {
          span {
            display: block;
            font-size: 12px;
            line-height: 30px;
            text-align: center;
          }
          font-size: 9px;
          height: 30px;
        }
      }
    }
    td, th {
      border: 1px solid #000;
      border-collapse: collapse;
    }
    .control_left {
      border-left: none;
    }
    .control_right {
      border-right: none;
    }
    .control_left, .control_right {
      border-top: none;
      border-bottom: none;
    }
    .footer {
      th, td {
        border: none !important;
        background: none !important;
      }
      th {
        a {
          display: block;
          padding-top: 4px;
        }
      }
    }
    .control_left, .control_right, .footer {
      a {
        &.cal_previous{
          padding-right: 10px;
        }
        &.cal_next{
          padding-left: 10px;
        }
        img {
          border: 0;
        }
        border: 0;
      }
    }
    .last_one {
      border: none !important;
      background: none !important;
    }
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
    background-color: #282e2c;
    color: #fefefe;
    border-bottom: 1px solid #000;
  }
  height: 100%;
}

.custom-tooltip {
  //overwritten opacity from bootstrap (tooltip.in).
  opacity: 1 !important;
  .tooltip-inner {
    div {
      span {
        &:first-of-type {
          width: 75px;
        }
        &:last-of-type {
          width: calc(100% - 75px);
        }
        &.campaignText {
          padding-top: 17px;
          padding-bottom: 3px;
        }
        float: left;
        display: block;
        word-wrap: break-word;
      }
    }
    max-width: 400px;
    text-align: left;
    background-color: #282e2c;
    border: 1px solid #000;
    border-radius: 0;
    display: block;
    overflow: auto;
  }
}
