.me__preview-edit {
  .preview-edit-tab-container {
    .preview-edit-tab-container-child {
      display: block;
      width: 100%;
      height: 100%;
    }

    display: block;
    width: 100%;
    height: calc(100% - 50px);
  }
}

.cms-preview-frame {
  height: 100%;
  width: 100%;
  border: 0;
}

.me__preview-edit-external {
    height: calc(100% - 145px);
}


