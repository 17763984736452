.elaine-mcp {
  .scroller {
    height: 100%;
  }
  .elaine-mcp-navigation {
    .e_form {
      /* Bootstrap Overrides */
      [class^="icon-"]:before,
      [class*=" icon-"]:before {
        position: relative;
        top: 2px;
        margin-right: 5px;
      }
      .elaine-mcp-navigation-loading {
        span {
          display: inline-block;
          vertical-align: top;
        }
        .elaine-spinner {
          top: -2px;
          left: 0;
          margin: 0;
          display: inline-block;
        }
        &.isLoading {
          display: inline-block;
        }
        display: none;
        position: relative;
        width: auto;
        height: 30px;
      }
      .e_icn_btn {
        &:hover {
          background: #6d891b;
          border-color: #6d891b;
          outline-color: #6d891b;
        }
        background: #282e2c;
        border-right: 1px solid #000;
      }
      .e_icn_btn:first-of-type {
        &:hover {
          background: #6d891b;
          border-color: #6d891b;
          outline-color: #6d891b;
        }
        margin-left: 20px;
        border-left: 1px solid #000;
        border-right: 1px solid #000;
        background: #282e2c;
      }
      .e_icn_btn:last-of-type {
        &:hover {
          background: #6d891b;
          border-color: #6d891b;
          outline-color: #6d891b;
        }
        margin-right: 20px;
        border-left: 1px solid #000;
        border-right: 1px solid #000;
        background: #282e2c;
      }
    }
    display: block;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    background: #282e2c
  }
  .elaine-mcp-container {
    .elaine-mcp-container-not-started {
      a {
        &:hover, &:active, &:focus {
          text-decoration: none;
        }
        color: #6d891b;
        cursor: pointer;
      }
      position: absolute;
      top: 50%;
      left: 44%;
      margin: 0 -50% 0 0;
      transform: translate(-50%, -50%);
      font-size: 18px;
      z-index: 1;
    }
    .elaine-mcp-thumbnails {
      .elaine-mcp-thumbnails-row {
        &:nth-child(odd) {
          background: #282e2c;
        }
        &:nth-child(even) {
          background: #1f2523;
        }
        .elaine-mcp-thumbnails-row-container {
          .elaine-mcp-thumbnails-row-container-inner {
            &.elaine-mcp-thumbnails-row-container-inner-no-border {
              border: none;
            }
            .elaine-mcp-thumbnails-row-container-image {
              img {
                outline: none;
              }
              display: block;
              width: 100%;
              height: calc(100% - 40px);
              overflow: hidden;
            }
            .elaine-mcp-thumbnails-row-container-headline {
              display: block;
              width: 100%;
              height: 40px;
              line-height: 40px;
              font-size: 18px;
            }
            display: block;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-right: 1px solid #000
          }
          display: inline-block;
          width: 25%;
          height: 250px;
          vertical-align: top;
          padding: 10px;
        }
        display: block;
        height: 250px;
        width: 100%;
      }
      display: block;
      width: 100%;
      height: 100%;
      float: left;
    }
    .elaine-mcp-detail {
      display: block;
      width: 100%;
      height: 100%;
      float: left;
    }
    .elaine-mcp-sidebar {
      .elaine-mcp-sidebar-row {
        &.clicked {
          background: #6d891b;
        }
        &:hover, &:active, &:focus {
          background: #6d891b;
          border-color: #6d891b;
          outline-color: #6d891b;
        }
        display: inline-block;
        width: 100%;
        height: 100%;
        vertical-align: middle;
        line-height: 30px;
        padding-left: 5px;
      }
      ul {
        padding-left: 0;
      }
      display: block;
      width: 200px;
      height: 100%;
      float: right;
      background: #282e2c;
    }
    display: block;
    width: 100%;
    height: calc(100% - 30px);
    border-top: 1px solid #000;

  }
  display: block;
  width: 100%;
  height: 100%;
  color: #fefefe;
}
