$tab-header__A0: themed('frame--bg-color') !default;
$tab-header__B0: themed('base--bg-color') !default;
$tab-header__B1: themed('base:accent--color') !default;
$tab-header__C0: themed('base--text-color') !default;
$tab-header__D0: artegic-icons; //@todo: app font from global variable
$tab-header__E0: themed('base:accent--color') !default;
$tab-header__F0: themed('error--color') !default;


tab-header{
  display: block;
  width: 100%;
  // Border attr for tag-object
  &.me__tab-header{
    &--border{
      .me__tab-header__list{
        border-bottom: 1px solid $tab-header__A0;
      }
      .me__tab-header__li{
        border-right: 1px solid $tab-header__A0;
      }
    }
  }
  .me__tab-header__list{
    display: block;
    width: 100%;
    height: 30px;
    padding-left: 0;
    margin: 0;
    list-style: none;
  }

  .me__tab-header__li{
    &.disabled {
      opacity: .5;
      pointer-events: none;
    }
    &.active{
      background-color: transparent;
      color: $tab-header__B0;
      border-bottom: 2px solid $tab-header__B1;
    }
    &:hover:not(.disabled) {
      border-bottom: 2px solid $tab-header__E0;
    }
    position: relative;
    display: block;
    float: left;
    height: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .me__tab-header__a{
    &.upper{
      text-transform: uppercase;
    }
    display: block;
    float:left;
    height: 100%;
    font-size: 12px;
    line-height: 30px;
    padding: 0 10px;
    background: transparent;
    border: none;
    color: $tab-header__C0;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
    margin-right: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .me__tab-header__i{
    &:focus, &:hover {
      outline: none;
    }
    &:hover{
      color: $tab-header__F0;
    }
    display: block;
    float:left;
    width:15px;
    cursor:pointer;
    height:30px;
    line-height:30px;
    font-family: $tab-header__D0;
    font-style: normal;
    color: $tab-header__C0;
  }
  a:hover {
    color: $tab-header__C0;
  }
}



