$ui-tabs__A0: themed('frame--bg-color') !default;
$ui-tabs__B0: themed('base--bg-color') !default;
//todo:preview comments remove
//usage in : me__form-row
.me__ui-tabs__tabs{

  border-bottom: 1px solid $ui-tabs__A0;
  //padding: 0;
  //flex-direction: row;
  display: flex;
  //width: 100%;

  .me__tab-header__list {
    border: none;
    li {
      border-right: 1px solid $ui-tabs__A0;
    }
  }

  //.me__ui-tabs__tab-header{
  //  display:inline-block;
  //  width:auto;
  //  overflow:hidden;
  //  height:30px;
  //}

  .me__ui-tabs__tabs-button{
    background-color: $ui-tabs__B0;
    border-right: 1px solid $ui-tabs__A0;

    &--right{
      margin-left: auto;
      border-left: 1px solid $ui-tabs__A0;
      border-right: none;
    }

  }

}
