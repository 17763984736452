//Accounting recipients
.accounting_recipients_six {
  .panel-group {
    .noMailing {
      color: #fefefe;
      font-weight: bold;
      padding-left: 10px;
    }
  }
  .acc_text {
    width: 33%;
    display: inline-block;
    padding-bottom: 10px;
  }
  .table_acc_show {
    padding: 0px 10px 10px 10px;
    div {
      color: #fefefe
    }
    h1 {
      font-size: 14pt;
    }
  }

  // Overwrite bootstrap classes
  .panel-default {
    .panel-heading {
      .title_border {
        border-top: 1px solid #000;
      }
      .accordion-toggle {
        &:after {
          font-family: $iconFontFamily;
          content: "[";
          float: left;
          color: #a8c33d;
          width: 20px;
        }
        &.collapsed {
          &:after {
            font-family: $iconFontFamily;
            content: ">";
            float: left;
            color: #a8c33d;
            width: 20px;
          }
        }
        border-bottom: 1px solid #000;
        display: block;
        color: #a8c33d;
        padding: 10px 15px;
        font-size: 12px;
        outline: none;
      }
      background: #282e2c;
      border-color: #282e2c;
      padding: 0;
      border-radius: 0;
    }
    background: #282e2c;
    border-color: #282e2c;
    box-shadow: none;
  }

  .tableRecipient {
    .header_date, .data_Date, .footer_date {
      width: 20%;
    }
    .header_count, .data_Count, .footer_count {
      width: 80%;
    }
    td {
      padding: 10px 20px;
    }
    thead {
      tr {
        th {
          &:last-child {
            border-right: none;
          }
          padding: 10px 15px;
          border-right: 1px solid #000;
        }
      }
    }
    tbody {
      tr {
        td {
          &:last-child {
            border-right: none;
          }
          border-right: 1px solid #000;
        }
      }
      tr:nth-child(even) {
        background: #6f6f6f;
      }
      tr:nth-child(odd) {
        background: #474949;
      }
    }
    tfoot {
      tr {
        td {
          &:last-child {
            border-right: none;
          }
          border-right: 1px solid #000;
        }
      }
      .footer_count {
        text-align: left;
      }
      border-collapse: collapse;
      border-bottom: 1px solid #000;
      border-top: 1px solid #000;
      color: #fefefe;
      font-weight: bold;
    }
    font-family: Tahoma, Verdana, Arial;
    font-size: 12px;
    width: 100%;
    text-align: left;
    color: #fefefe;
    background: #282e2c;
  }
  height: 100%;
}
