$icn__A0: themed('element-action--text-color') !default;

.me__icn{
  &.me__icn-5x{
    font-size: 3em;
  }
  &.me__icn-4x{
    font-size: 2.5em;
  }
  &.me__icn-3x{
    font-size: 2em;
  }
  &.me__icn-2x{
    font-size: 1.5em;
  }
  color: $icn__A0;
  font-size: 14px;
}
