
$devices-tabs__A0: themed('frame--bg-color') !default;
$devices-tabs__B0: themed('frame--bg-color') !default;
$devices-tabs__C0: themed('frame--bg-color') !default;
$devices-tabs__D0: themed('base--bg-color') !default;
$devices-tabs__E0: themed('frame--bg-color') !default;


@mixin devicesTabs() {

  .me__devices-tabs__devices {
    border: 1px solid $devices-tabs__A0;
    padding: 0;
    flex-direction: column;
  }

  .me__devices-tabs__tabs {
    display: flex;
    width: 100%;
    padding: 0px;
    border-bottom: 1px solid $devices-tabs__B0;

    .me__tab-header__list {
      border: none;

      li {
        border-right: 1px solid $devices-tabs__C0;
      }
    }
  }

  .me__devices-tabs__tab-header{
    display:inline-block;
    width:auto;
    overflow:hidden;
    height:30px;
  }

  .me__devices-tabs__tabs-add{
    background-color: $devices-tabs__D0 !important;
    border-right: 1px solid $devices-tabs__E0;
  }

  .me__devices-tabs__frames {
    flex-direction: column;
    padding: 0 10px;
  }

  .me__devices-tabs__button{
    order:0;
  }

  .me__devices-tabs__input{
    flex-grow:1;
  }

  .me__devices-tabs__imageurl-text{
    flex-grow:1;
  }
}
