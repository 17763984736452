.me__article-add-button{



}

.md-open-menu-container,
.md-open-menu-container * {
  .me__article-add-button__opt-group{
    color: #a8c33d;
    border-bottom: 1px solid #a8c33d;
    margin-top: 5px;
    margin-left: 16px;
    margin-right: 16px;
    font-size: 14px;
  }
  .me__article-add-button__opt-group-item{
    padding-left: 16px
  }
  -webkit-transition: none !important;
  -moz-transition: none !important;
  transition: none !important;
}