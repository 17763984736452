$preview_dispatch__A0: themed('base--text-color');
$preview_dispatch__A1: themed('warning--color');

.me__preview-dispatch {

  &__preview-outdated {
    span {
      display: inline-flex;
      width: auto;
      height: 100%;
      padding-top: 10px;
      color: $preview_dispatch__A1;
    }

    p {
      display: inline-flex;
      height: 100%;
      flex-grow: 1;
      margin-left: 10px;
      color: $preview_dispatch__A0;
    }

    display: flex;
  }

}
