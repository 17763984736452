// ________________________________________________________________ MIXINS

$inputText__A0: themed('form-element--bg-color');
$inputText__A1: themed('form-element--text-color');
$inputText__B0: themed('form-element--text-color');

@mixin labelText() {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  left: 5px;
  top: -22px;
  color:$inputText__B0;
}

@mixin inputText() {
  background-color: $inputText__A0;
  color: $inputText__A1;
  border: none;
  padding: 5px;
  padding: 0 10px;
  border: none;
  line-height: 30px;
}


// ________________________________________________________________ GRID

$inputText__C0: themed('form-element--text-color');
$inputText__D0: themed('error--color');
$structure__E0: themed('frame--bg-color') !default;

/*
EXAMPLE ELEMENTS STRUCTURE:

  div.me__form.me__form--margin
    div.me__form-block  (*optional element)
      div.me__form-rows( --single )
        div.me__form-row-container
          md-input-container
            label
            input
*/

.me__form{

  &--margin{
    margin: 0 10px;
  }

  &--preview-config-margin{
      margin: 0 0 0 10px;
      padding-right: 20px;
  }
}

.me__form-group{

  .me__form-group-label{
    margin: 10px 0;
    height: 15px;
    font-size: 20px;
    color:$inputText__B0;
  }
}

.me__form-row{
  &:not( .me__form-row--single ):last-of-type{
    margin-bottom: 20px;
  }
  &:not( .me__form-row--single ){
    margin: 25px 0 0;
  }
  &.me__form-row--single{
    height: 100%;
  }

  &.me__form-row--border{
    border: 1px solid $structure__E0;
    height:auto;
  }

  display:block;
  width:100%;
}

//---------- single form-row-container variation
.me__form-row--single{
  .me__form-row-container{
    height:100%;

    >*{
      margin: 0 2px;
    }

    >*:first-child{
      margin-left: 0;
    }

    >*:last-child{
      margin-left: 0;
    }

    >*:only-child{
      margin: 0;
    }
  }
}

//----------
.me__form-row{
  .elaine-dropdown {
    margin: 0;
    padding: 0;
    background: transparent;
  }
  .datetimepicker {
    .table{
      .left,.right,.switch{
        &:hover{
          background: #6d891b;
        }
      }
      td{
        &.disabled{
          opacity: .5;
          cursor: default;
        }
        &.day{
          &.current{
            color: #fefefe;
            background: transparent;
            //border: 1px solid #6d891b;
            border: none;
            outline: 1px solid #6d891b;
            outline-offset: -1px;
          }
          &:hover:not(.disabled),&.active:not(.disabled){
            background: #6d891b;
          }
        }
        span{
          &.disabled{
            opacity: .5;
            cursor: default;
          }
          &.hour,&.minute{
            &:hover:not(.disabled) {
              color: #fefefe;
              background: #6d891b;
            }
          }
          &.current{
            &:hover:not(.disabled){
              color: #fefefe;
              background: #6d891b;
            }
            background: transparent;
            border: 1px solid #6d891b;

          }
          border-radius: 0;
        }
      }

    }
    background: #404644;
    border-radius: 0;
    color: #fefefe;
  }
  .ui-spinner{
    &.ui-state-disabled{
      .ui-spinner-button {
        &:hover {
          background: #404644;
        }
        cursor: default;
        opacity: .3;
      }
    }
    [type="number"] {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }
    .ui-spinner-button {
      &.ui-spinner-up{
        top: 0;
      }
      &.ui-spinner-down{
        top: 15px;
      }
      .ui-icon{

      }
      display: inline-block;
      width: 30px;
      height: 15px;
      line-height: 15px;
      position: absolute;
      right: 0;
      text-align: center;
      text-decoration: none;
      color: #fefefe;
      border-left: 1px solid #000;
      cursor: pointer;
      outline: none;
    }
    position: relative;
    background: #404644;
    display: block;
  }
  .me__form-row-container{

    &--relative {
      position:relative;
    }

    .date-element {
      &[disabled]{
        .date-toggle {
          &.dropdown-toggle {
            &:hover {
              .input-group-addon {
                background: #404644;
              }
            }
          }
        }
      }
      &.invisible{
        display: none;
      }
      &.timeOnly{
        table{
          &.hour-view, &.minute-view{
            thead{
              display: none;
            }
          }
        }
      }
      .date-toggle{
        &:hover:not( [disabled] ){
          .input-group-addon {
            background: #6d891b;
          }
        }
        float: left;
        width: calc( 100% - 30px );
        overflow: hidden;
      }
      input {
        display: inline-block;
        width: calc( 100% - 30px );
      }
      span{
        &[disabled]{
          opacity: .5;
        }
        &.form-control{
          &.small {
            width: 20%;
          }
          display: inline-block;
          width: calc( 100% - 30px );
          line-height: 30px;
          padding: 0 5px;
        }
      }
      .date-input-group{
        display: inline-block;
        width: calc( 100% - 33px );
      }
      .input-group-addon{
        &.removeDate{
          &:hover:not( [disabled] ){
            background: #731513;
          }
        }
        &.dateAddon {
          &:hover:not( [disabled] ) {
            background: #6d891b;
          }
        }
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        padding: 0 5px;
        border: none;
        border-radius: 0;
        background: #404644;
        border-left: 1px solid #000
      }
      width: 100%;
      display: inline-block;
    }

    md-checkbox{
      .md-container{
        .md-icon{
          &:after{
            top: -2px;
            left: 2px;
            height: 12px;
          }
          width: 15px;
          height: 15px;
          color: #fefefe;
          border:1px solid transparent;
        }
        width: 15px;
        height: 15px;
        background: #404644;
        left: 5px;
      }
      .md-label{
        color: #fefefe;
        height: 20px;
        line-height: 20px;
      }
    }
    //md-switch
    >.me__button.md-button,
    > md-input-container {
      margin: 0 2px;
    }

    >.me__button.md-button{
      &:last-of-type{
        margin-right: 0;
      }
    }

    >*:first-child {
      margin-left: 0;
    }

    >*:last-child{
      margin-right: 0;
    }

    >*:only-child{
      flex-grow:1;
      margin: 0;
    }

    display: flex;
    margin: 0;
    padding:0;
    flex-wrap:nowrap;
  }
}


.me__form-row__text{
  color: $inputText__C0;
}


md-input-container .md-input.ng-invalid.ng-dirty {
  border: 1px solid $inputText__D0;
}

//@todo: arrow to required fields
//md-input-container .md-input:required{
//  &:before {
//    content:"\3e";
//    font-family:"elaine-six";
//    display:flex;
//    width:12px;
//    line-height:1;
//    color:$inputText__D0;
//  }
//}

.me__form-item-expand{
  flex-grow: 1;
}

.me__form-input-error{
  position:absolute;
  right:0px;
  background-color: $inputText__D0;
  color: $inputText__A1;
  padding: 0 4px;
  z-index: 100;

  &--bottom{
    bottom:0;
  }
 }





