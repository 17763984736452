$sortable-list-preview__A0: #fefefe !default;
$sortable-list-preview__B0: #fefefe !default;
$sortable-list-preview__C0: themed('base--bg-color') !default;
$sortable-list-preview__borders: themed('frame--bg-color') !default;

sortable-list-preview{
  position: absolute;
  top: 40px;
  left: 10px;
}

.me__sortable-list-preview{
  &.ng-enter,&.ng-leave{
    transition: all .5s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 200ms;
  }
  &.ng-enter,
  &.ng-leave.ng-leave-active {
    opacity: 0;
  }
  &.ng-leave,
  &.ng-enter.ng-enter-active {
    opacity: 1;
  }

  .me__sortable-list-preview__container{
    border: 1px solid $sortable-list-preview__borders;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: $sortable-list-preview__A0;
  }

  .me__sortable-list-preview__inner{
    background: $sortable-list-preview__B0;
  }

  .me__sortable-list-preview__failure{
    border: 1px solid $sortable-list-preview__borders;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 10px;
    line-height: 1;
  }

  display: table;
  min-width: 600px;
  position: absolute;
  max-width: 650px;
  z-index: 1250;
  padding: 10px;
  border: 1px solid $sortable-list-preview__borders;
  background: $sortable-list-preview__C0;
  transform-origin: left top -200px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.6);
  box-shadow: 0 6px 12px rgba(0,0,0,0.6);
  -moz-border-radius: 0;
}
