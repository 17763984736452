//@todo: better theme color name
$preview__A0: themed('frame--text-color')!default;

.me__preview {
  &__container{
    display: block;
    position: relative;
    width: 100%;
    height: calc(100% - 30px);
    padding: 0;
    margin: 0 auto;
    background-color: #282e2c;
    overflow: auto;
    text-align: center;
    color: #000;
    border-right: 0.8px solid #444444;
  }
  &__inner {
    display: block;
    margin: 0 auto;
    height: 100%;
    -moz-transition: .75s linear width;
    -webkit-transition: .75s linear width;
    transition: .75s linear width;
  }
  &-show{
    &.article-only{
      margin-top: 30px;
    }
    &__mobile-img {
      width: 50%;
      margin: 0 auto;
      min-width: 500px;
    }
    &__mobile-img_editor{
      width: 100%;
      margin: 0 auto;
      min-width: 0px;
    }
    display: block;
    position: relative;
    margin: 0 auto;
  }
  &__view{
    subjects, textcontent{
      display: none;
    }
    &.push{
      display: block;
      position: absolute;
      left: 50%;
      top: 100px;
      transform: translateX(-50%);
      overflow: auto !important;
    }
    &.sms{
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      overflow: auto !important;
      background: #fefefe;
      color: #000;
      font-size: 16px;
      word-wrap: break-word;
      white-space: pre-line;
    }
    overflow: hidden;
    padding: 8px;
    font-family: consolas, monaco, monospace;
    background: #fefefe;
    word-wrap: break-word;
    overflow: auto !important;
    text-align: left;
  }
  display: block;
  width: 100%;
  height: 100%;
  overflow: visible;
  position: relative;

  .me__preview__outdated-overlay{
    display:flex;
    position:absolute;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,.5);
    align-items: center;
    top:0;
    left:0;
    cursor:pointer;
    z-index: 1;
  }

  .me__preview__outdated-advice{
    color:white;
    font-size: 20px;
    width:100%;
    text-align:center;
  }

  .me__preview-single-subject{
    border-bottom: 1px solid #000;
    color: #fefefe;

    &__label{
      height:30px;
      line-height:30px;
      padding: 0 10px;
    }

    &__text{
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      width: 100%;
      background-color: #404644;
    }
  }

}
