$categories-area__A0: #6d891b;
$categories-area__A1: #6d891b;
$categories-area__A2: #000000;
$categories-area__B1: #fefefe;
$categories-area__C1: #fefefe;
$categories-area__D1: #fefefe;


.e__categories-area{

  position: relative;
  height: calc(100% - 75px);
  display: block;

  .e__categories-area__position{

    &--top{
      top: 0;
    }
    &--bottom{
      bottom: 0;
    }
    position: absolute;
    width: 100%;
  }

  .e__categories-area__list{
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .e__categories-area__list-item{
    &:not( .e__categories-area__list-item--disabled ) {
      &:hover {
        .e__categories-area__list-item-link {
          .e__categories-area__link-label {
            visibility: visible;
          }
        }
        background: $categories-area__A1;
      }
    }

    &--active{
      background: transparent;
      border-right: 4px solid $categories-area__A0;
    }

    &--disabled{
      opacity: 0.6;
      cursor: default;
    }

    display: block;
    height: 65px;
    width: 74px;
    border-right: 4px solid $categories-area__A2;
    cursor: pointer;
  }

  .e__categories-area__list-item-link{
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    outline: none;
    color: $categories-area__B1;
    padding: 18px 0 0 0;
  }

  .e__categories-area__link-icon{
    img {
      width: 100%;
      height: 30px;
    }
    display: block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 2.2em;
    color: $categories-area__C1;
  }

  .e__categories-area__link-label{
    display: block;
    width: 100%;
    height: 10px;
    line-height: 10px;
    color: $categories-area__D1;
    text-transform: uppercase;
    font-size: 8px;
    text-align: center;
    visibility: hidden;
    margin-top: 3px;
  }

}
