$composing__A0: themed('element-action--text-color') !default;
$composing__A1: themed('element-action:active-alternative--bg-color') !default;

composing{
  &.activeParentArticle {
    padding: 32px;
    display: block;

    .me__article__canvas {
      overflow: hidden;
    }
  }
  .me__composing{

    &__inner{
      position:relative;
    }
    padding: 0 0;
    margin: 0;
    min-height:30px;
    position:relative;

    .me__composing__empty-button{
      width:100%;
      height:100%;
      text-align: center;
    }

    .me__composing__info{
      position: absolute;
      display: none;
      height: 20px;
      line-height: 20px;
      background: rgba($composing__A1, .6);
      color: $composing__A0;
      padding: 0 10px;
      white-space: nowrap;
      top:-45px; // like parent-padding
      left:-25px; // like parent-padding
      width: calc( 100% + 50px);
      font-size:12px;
      overflow:hidden;
    }

  }

  &.structured{
    display: block;
    padding: 25px;
    margin: 25px;
    border: 1px solid #ccc;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.5);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.5);
    z-index: -1;

    .me__composing.show-infos{
      > .me__composing__info{
          display:block;

      }
    }

    .me__composing__inner{
      padding-top:5px;
    }
  }

  transition-property: margin-bottom,margin-top,margin-left,margin-right,outline-color,outline-width,box-shadow, padding-bottom,padding-left,padding-right,padding-top;
  transition-duration: .35s;
  transition-timing-function: ease-in-out;
  margin: 0;
  padding: 0;
  outline: none;
  box-shadow: none;
}
