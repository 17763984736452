$md-expansion-panel__A0: themed('base--bg-color') !default;
$md-expansion-panel__B0: themed('base--bg-color') !default;
$md-expansion-panel__B1: themed('frame--bg-color') !default;
$md-expansion-panel__C0: themed('base:accent--text-color') !default;
$md-expansion-panel__D0: themed('base:accent--text-color') !default;
$md-expansion-panel__E0: themed('base--bg-color') !default;
$md-expansion-panel__F0: themed('frame--bg-color') !default;


//Expansion override //@todo: check please again

md-expansion-panel md-expansion-panel-collapsed,
md-expansion-panel .md-expansion-panel-footer-container,
md-expansion-panel .md-expansion-panel-header-container {
  background: $md-expansion-panel__A0;
}

md-expansion-panel md-expansion-panel-header .md-expansion-panel-header-container {
  border-color: transparent;
}

md-expansion-panel {
  &.md-open{
    margin: 0;
  }
  background: $md-expansion-panel__B0;
  box-shadow: none;
  border-bottom:1px solid $md-expansion-panel__B1;
  margin: 0;
}

md-expansion-panel {
  md-expansion-panel-collapsed,
  md-expansion-panel-expanded,
  md-expansion-panel-collapsed,
  md-expansion-panel-expanded {
    .md-expansion-panel-icon {
      margin-left: 0;
    }
    md-icon {
      svg {
        fill: $md-expansion-panel__C0;
      }
    }
    align-items: center;
  }
}

md-expansion-panel md-expansion-panel-collapsed .md-title, md-expansion-panel md-expansion-panel-collapsed .md-summary, md-expansion-panel .md-expansion-panel-footer-container .md-title, md-expansion-panel .md-expansion-panel-footer-container .md-summary, md-expansion-panel .md-expansion-panel-header-container .md-title, md-expansion-panel .md-expansion-panel-header-container .md-summary{
  color: $md-expansion-panel__D0;
  font-size: 12px;
  line-height: 30px;
  font-weight: normal;
  max-width: none;
}

md-expansion-panel .md-expansion-panel-icon svg{
  fill: $md-expansion-panel__C0;
}


md-expansion-panel:not(.md-open):not([disabled]):focus,
md-expansion-panel:not(.md-open):not([disabled]):focus md-expansion-panel-collapsed {
  background: $md-expansion-panel__E0;
}

md-expansion-panel-header, md-expansion-panel-collapsed{
  cursor:pointer;
}

md-expansion-panel md-expansion-panel-expanded md-expansion-panel-content{
  padding: 0;
}

md-expansion-panel md-expansion-panel-collapsed{
  min-height: 30px;
  line-height: 30px;
  padding: 0 10px;
}

md-expansion-panel md-expansion-panel-expanded{
  md-expansion-panel-header{
    &.md-stick{
      .md-expansion-panel-header-container{
        z-index: -1;
      }
    }
    .md-expansion-panel-header-container{
      min-height: 30px;
      line-height: 30px;
      padding: 0;
    }
    min-height: 30px;
    line-height: 30px;
    padding: 0 10px;
    border-bottom: 1px solid $md-expansion-panel__F0;
  }
  min-height: 30px;
}
